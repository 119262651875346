<template>
  <div>
    <el-table
      style="width: 100%"
      header-row-class-name="data-table-header"
      :data="tableData"
    >
      <el-table-column label="应用图标" style="text-align: center">
        <template slot-scope="scope">
          <img :src="scope.row.logoUrl" alt="" style="width: 80px" />
        </template>
      </el-table-column>
      <el-table-column prop="appName" label="应用名称	"> </el-table-column>
      <el-table-column prop="pageName" label="包名"> </el-table-column>
      <el-table-column prop="version" label="版本号"></el-table-column>
      <el-table-column prop="fileSize" label="文件大小"></el-table-column>

      <el-table-column prop="createTime" label="下载时间"> </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 20px"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[5, 40, 80, 100]"
      :page-size="count"
      layout="total, sizes, prev, pager, next, jumper"
      :page-count="pages"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  created() {
    this.update(this.page, this.count);
  },
  props: ["appId", "deductFlag"],
  methods: {
    downCert(id) {
      console.log(id);
      var that = this;
      that.$load(1, "查询中");
      this.$http
        .get("/softwareDistribute/downCert?id=" + id)
        .then(function (response) {
          that.$load(0);
          window.location.href = response.data.url;
        })
        .catch(function (error) {
          that.$load(0);
          that.$message, error("未知失败");
        });
    },
    handleSizeChange(val) {
      console.log("每页数量" + val);
      this.count = val;
      this.update(this.page, this.count);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.update(this.page, this.count);
      console.log(`当前页: ${val}`);
    },
    update(pageNum, pageSize) {
      var that = this;
      that.$load(1, "查询中");
      let deductFlag = this.deductFlag ? this.deductFlag : "";
      this.$http
        .get(
          "/softwareDistribute/queryDownById?pageNum=" +
            pageNum +
            "&pageSize=" +
            pageSize +
            "&appId=" +
            this.appId +
            "&deductFlag=" +
            deductFlag
        )
        .then(function (response) {
          that.$load(0);
          if (response.data.code == 0) {
            console.log(response.data.data);
            that.tableData = [];
            for (let res in response.data.data) {
              var temp = response.data.data[res];
              if (temp.status === "点击下载") {
                temp.status = "下载成功";
              }
              that.tableData.push(temp);
            }
            that.pages = response.data.pages;
            that.total = response.data.total;
          } else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
          that.$load(0);
          that.$message, error("查询失败");
        });
    },
    headClass() {
      //表头居中显示
      return "text-align:center";
    },
    rowClass() {
      //表格数据居中显示
      return "text-align:center";
    },
  },
  data() {
    return {
      tableData: [],
      count: 5,
      page: 1,
      pages: 0,
      total: 1,
    };
  },
};
</script>

<style lang="less" >
.el-pagination {
  .el-input__inner {
    height: 30px;
  }
}
</style>

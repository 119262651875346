<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style>
.data-table-header th {
  background: #f3f7ff !important;
  color: #333;
}
</style>

<template>
  <div id="myCharts" :style="{ width: '100%', height: '404px' }"></div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.recent7DaysData();
    this.$nextTick(() => {});
  },
  methods: {
    async recent7DaysData() {
      let result = await this.$http.get("/softwareDistribute/recent7DaysData");
      console.log(result, "result");
      let {
        data: { data, code },
      } = result;
      if (code !== 0) return;
      let xAxis = [];
      let series2 = [];
      data.forEach((item) => {
        xAxis.push(item.dateStr);
        series2.push(item.downloadCount);
      });
      let options = {
        xAxis: {
          type: "category",
          data: xAxis,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "下载数",
            data: series2,
            type: "line",
          },
        ],
      };
      this.canvasEchart(options);
    },
    canvasEchart(options) {
      var myChart = this.$echarts.init(document.getElementById("myCharts"));
      let option = {
        tooltip: {
          trigger: "axis",
          formatter: (data) => {
            return `${data[0].seriesName}:${data[0].value}  `;
          },
        },
        ...options,
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang='less' scoped>
</style>

<template>
  <div class="app_edit">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/AppAll' }"
        >应用列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>应用修改</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 20px 0">
      <div slot="header">
        <el-row>
          <el-col :span="2">
            <img :src="detailInfo.logoUrl" alt="" style="width: 80px" />
          </el-col>
          <el-col :span="3" :offset="1">
            <div class="title">{{ detailInfo.appName }}</div>
            <div class="info">苹果版本:{{ detailInfo.version }}</div>
          </el-col>
          <el-col :span="3" :offset="16">
            <el-button type="primary" @click="updateIpa">更新应用</el-button>
            <UploadApp
              ref="UploadApp"
              actionUrl="/softwareDistribute/updateIpa"
              :uuid="uuid"
              @uoloadSuccess="uoloadSuccess"
            />
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="2">
          <div class="second_title">大小</div>
          <div>{{ detailInfo.size }}</div>
        </el-col>
        <el-col :span="2">
          <div class="second_title">总下载数</div>
          <div>{{ detailInfo.downloadCount }}</div>
        </el-col>
        <el-col :span="5">
          <div class="second_title">Bundle ID</div>
          <div style="display: flex">
            <div>{{ detailInfo.pageName }}</div>
          </div>
        </el-col>
        <el-col :span="13">
          <div class="second_title">下载地址</div>
          <div style="display: flex">
            <el-link
              :href="detailInfo.url"
              type="primary"
              style="margin-right: 10px"
              >{{ detailInfo.url }}</el-link
            >
            <el-popover placement="top-start" width="200" trigger="hover">
              <div id="qrcode" ref="qrcode"></div>
              <div id="qrcode2" ref="qrcode2" slot="reference"></div>
            </el-popover>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-tabs type="border-card" @tab-click="changeTap" v-model="active">
      <el-tab-pane label="应用信息" name="1">
        <AppPane1 ref="AppPane1" :id="appId" />
      </el-tab-pane>
      <el-tab-pane label="更新记录" name="2">
        <!-- <DownRecord :appId="appId" deductFlag="1" /> -->
        <AppPane2 :appId="appId" ref="AppPane2" />
      </el-tab-pane>
      <!-- <el-tab-pane label="历史版本"> <AppPane3 /></el-tab-pane> -->
      <el-tab-pane label="下载记录" name="3">
        <DownRecord :appId="appId" />
      </el-tab-pane>
      <!-- <el-tab-pane label="合并记录">
        <AppPane5 />
      </el-tab-pane> -->
      <!-- <el-tab-pane label="轮播图">
        <AppPane6 />
      </el-tab-pane>
      <el-tab-pane label="反作弊">
        <AppPane7 />
      </el-tab-pane>
      <el-tab-pane label="渠道">
        <AppPane8 />
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import AppPane1 from "./AppPane1";
import AppPane2 from "./AppPane2";
import AppPane3 from "./AppPane3";
import AppPane4 from "./AppPane4";
import AppPane5 from "./AppPane5";
import AppPane6 from "./AppPane6";
import AppPane7 from "./AppPane7";
import AppPane8 from "./AppPane8";
import DownRecord from "@/components/DownRecord";
import QRCode from "qrcodejs2";
import UploadApp from "@/components/UploadApp";

export default {
  name: "",
  props: ["appId"],
  data() {
    return {
      detailInfo: {},
      active: "1",
    };
  },
  components: {
    UploadApp,
    AppPane1,
    AppPane2,
    AppPane3,
    AppPane4,
    AppPane5,
    AppPane6,
    AppPane7,
    AppPane8,
    DownRecord,
  },
  created() {
    console.log(this.$route.query);
    this.appId = this.$route.query.appId;
  },
  mounted() {
    this.queryPackDetail();
  },
  methods: {
    uoloadSuccess() {
      this.$refs.AppPane2.update();
    },
    changeTap() {
      console.log(this.active);
      if (this.active === "2") {
        this.$refs.AppPane2.update();
      }
    },
    queryPackDetail() {
      this.$http
        .post("/softwareDistribute/queryPackDetail?id=" + this.appId)
        .then((res) => {
          let {
            data: {
              code,
              data,
              data: { appName, introduce, uuid },
            },
          } = res;
          if (code === 0) {
            this.uuid = uuid;
            this.detailInfo = data;
            this.$refs.AppPane1.appName = appName;
            this.$refs.AppPane1.introduce = introduce;
            console.log(appName, "appName");
            this.qrcode();
          }
        });
    },
    updateIpa() {
      this.$refs.UploadApp.dialogVisible = true;
    },
    qrcode(e, n) {
      new QRCode("qrcode", {
        width: 200, // 二维码宽度，单位像素
        height: 200, // 二维码高度，单位像素
        text: this.detailInfo.url, // 生成二维码的链接
      });

      new QRCode("qrcode2", {
        width: 30, // 二维码宽度，单位像素
        height: 30, // 二维码高度，单位像素
        text: this.detailInfo.url, // 生成二维码的链接
      });
    },
  },
};
</script>
<style lang='less' scoped>
.app_edit {
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
  .info {
    color: #666;
    line-height: 24px;
  }
  .second_title {
    color: #777;
    margin-bottom: 5px;
  }
}
</style>

<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    header-row-class-name="data-table-header"
  >
    <el-table-column prop="date" label="设备" width="180"> </el-table-column>
    <el-table-column prop="name" label="系统版本" width="180">
    </el-table-column>
    <el-table-column prop="address" label="APP版本"> </el-table-column>
    <el-table-column prop="address" label="下载时间"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='less' scoped>
</style>

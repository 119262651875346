<template>
  <div class="">
    <el-row>
      <el-col :span="5">
        <el-input type="small" size="small"></el-input>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-button type="primary" size="small">自定义生成</el-button>
        <el-button type="primary" size="small">随机生成</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 20px"
      header-row-class-name="data-table-header"
    >
      <el-table-column prop="date" label="渠道码"> </el-table-column>
      <el-table-column prop="name" label="地址"> </el-table-column>
      <el-table-column prop="address" label="安装上限(点击修改,0为不限制)	">
      </el-table-column>
      <el-table-column prop="address" label="安装次数	"> </el-table-column>
      <el-table-column prop="address" label="下载次数		"> </el-table-column>
      <el-table-column prop="address" label="生成时间		"> </el-table-column>
      <el-table-column label="操作" width="50">
        <template slot-scope="scope">
          <el-popconfirm
            :title="`确定删除${scope.row.appName}吗？`"
            @confirm="handleClick(scope.row.id)"
          >
            <el-button slot="reference" type="danger" size="mini"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='less' scoped>
</style>

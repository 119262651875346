<template>
  <el-dialog title="新增应用" :visible.sync="dialogVisible" width="40%">
    <div style="margin-bottom: 20px; line-height: 25px">
      <div style="font-size: 16px; font-weight: bold">上传ipa提示：</div>
      ipa包里必须要有embedded.mobileprovision，确保权限完整；
      <br />
      ipa包里确保info.plist里的bundleid与二进制执行文件中的bundleid一致；
      <br />
      ipa包最好是没有被其他机构重签过的包，不然可能会导致应用闪退；
    </div>
    <el-upload
      :on-success="success"
      class="uploadHead"
      with-credentials
      drag
      :action="this.$http.defaults.baseURL + this.actionUrl"
      :data="this.param"
      name="ipa"
      accept=".ipa"
      :multiple="false"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传ipa文件，且不超过2048MB
      </div>
    </el-upload>
  </el-dialog>
</template>

<script>
export default {
  name: "UploadApp",
  props: ["actionUrl", "uuid"],
  computed: {
    param() {
      let param = this.uuid ? { uuid: this.uuid } : "";
      return param;
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    success(e) {
      if (e.code === 4) {
        this.$message.error(e.message);
        window.sessionStorage.removeItem("user");
        this.$router.push("/login");
      } else if (e.code === 0) {
        this.$message.success(e.message + ":请前往我的应用查看");
        this.$emit("uoloadSuccess");
      } else {
        this.$message.error(e.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>

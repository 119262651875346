<template>
  <div class="">
    <el-upload
      :before-remove="beforeUpload"
      accept=".jpg,.png"
      :on-change="uploadImagesChange"
      class="upload-demo"
      :action="this.$http.defaults.baseURL + '/softwareDistribute/uploadImg'"
      :file-list="fileList"
      limit="4"
      list-type="picture"
      :auto-upload="false"
    >
      <el-button style="margin-bottom: 20px" size="small" type="primary"
        >上传轮播图</el-button
      >
    </el-upload>
    <el-table
      :data="tableData"
      style="width: 100%"
      header-row-class-name="data-table-header"
    >
      <el-table-column prop="date" label="轮播图" width="180">
      </el-table-column>
      <el-table-column prop="name" label="上传时间	" width="180">
      </el-table-column>
      <el-table-column prop="address" label="操作"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      dialogVisible3: false,
    };
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='less' scoped>
</style>

<template>
  <div>
    <AppHeader @uoloadSuccess="uoloadSuccess" />
    <h3 class="big_header">常用应用</h3>
    <el-table
      header-row-class-name="data-table-header"
      :data="tableData"
      style="width: 100%"
      :header-cell-style="headClass"
      :cell-style="rowClass"
    >
      <el-table-column prop="id" label="应用图标">
        <template slot-scope="scope">
          <el-avatar :src="scope.row.logoUrl"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="appName" label="应用名称"> </el-table-column>
      <el-table-column prop="version" label="苹果版本"> </el-table-column>
      <el-table-column prop="apk" label="安卓合并">
        <template slot-scope="scope">
          <el-tag
            style="cursor: pointer"
            :type="scope.row.apk === '未上传' ? 'primary' : 'success'"
            @click="dialogVisibleOpen(scope.row.uuid)"
          >
            {{ scope.row.apk === "未上传" ? "点击上传" : " 已上传 更新" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="下载地址">
        <template slot-scope="scope">
          <el-button @click="copy(scope.row.url)" type="text" size="small"
            >复制地址</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="url" label="二维码">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="200"
            trigger="hover"
            @hide="hideQrcode(`qrcode${scope.$index}`)"
            @show="showQrcode(scope.row.url, `qrcode${scope.$index}`)"
          >
            <div
              :id="`qrcode${scope.$index}`"
              :ref="`qrcode${scope.$index}`"
            ></div>
            <div slot="reference" style="cursor: pointer; color: #409eff">
              查看二维码
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="downCode" label="上架状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeEnaled(scope.row.id)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="accountCount" label="装机数量"> </el-table-column>
      <el-table-column prop="downloadCount" label="下载次数"> </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="150"
      ></el-table-column>

      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 5px"
            @click="toEdit(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="success"
            size="mini"
            style="margin-right: 5px"
            @click="refreshUrl(scope.row.uuid, scope.$index)"
            >刷新链接</el-button
          >
          <el-popconfirm
            :title="`确定删除${scope.row.appName}吗？`"
            @confirm="handleClick(scope.row.uuid)"
          >
            <el-button slot="reference" type="danger" size="mini"
              >删除</el-button
            >
          </el-popconfirm>
        </template></el-table-column
      >
    </el-table>

    <el-pagination
      style="text-align: center; margin-top: 20px"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[5, 40, 80, 100]"
      :page-size="count"
      layout="total, sizes, prev, pager, next, jumper"
      :page-count="pages"
      :total="total"
    >
    </el-pagination>
    <h3>下载统计</h3>
    <AppEcharts />
    <el-dialog
      title="简介编辑"
      :visible.sync="dialogVisible2"
      center
      width="30%"
    >
      <el-input
        maxlength="100"
        type="textarea"
        :rows="3"
        placeholder="请输入简介,100字以内"
        v-model="introduce"
      >
      </el-input>
      <p></p>
      <el-button @click="dialogVisible2 = false">取 消</el-button>
      <el-button type="primary" @click="updateIntroducePost()">确 定</el-button>
    </el-dialog>

    <el-dialog
      title="购买地址"
      :visible.sync="dialogVisible4"
      center
      width="30%"
    >
      <el-input
        maxlength="1000"
        type="textarea"
        :rows="3"
        placeholder="请输入购买地址"
        v-model="buyDownCodeUrl"
      >
      </el-input>
      <p></p>
      <el-button @click="dialogVisible4 = false">取 消</el-button>
      <el-button type="primary" @click="updateDownCodeUrlPost()"
        >确 定</el-button
      >
    </el-dialog>

    <el-dialog title="轮播图" :visible.sync="dialogVisible3" center width="30%">
      <el-upload
        :before-remove="beforeUpload"
        accept=".jpg,.png"
        :on-change="uploadImagesChange"
        class="upload-demo"
        :action="this.$http.defaults.baseURL + '/softwareDistribute/uploadImg'"
        :file-list="fileList"
        limit="4"
        list-type="picture"
        :auto-upload="false"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          v-on:click.stop="uploadImagesPost()"
          >上传到服务器</el-button
        >
        <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      center
      width="30%"
      title="安卓上传"
    >
      <span slot="footer" class="dialog-footer">
        <el-upload
          :data="{ uuid: this.uuid }"
          :on-success="success"
          class="uploadHead"
          with-credentials
          drag
          :action="
            this.$http.defaults.baseURL + '/softwareDistribute/uploadApk'
          "
          name="apk"
          accept=".apk"
          :multiple="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传apk文件，且不超过2048MB
          </div>
        </el-upload>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AppHeader from "./AppHeader.vue";
import AppEcharts from "./AppEcharts";
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      id: { id: "" },
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      introduce: "",
      id2: "",
      id3: "",
      buyDownCodeUrl: "",
      fileList: [],
      img: [],
      count: 5,
      page: 1,
      pages: 0,
      total: 0,
      account: "",
      type: "",
    };
  },
  components: { AppHeader, AppEcharts },
  created() {
    const user = JSON.parse(window.sessionStorage.getItem("user"));
    this.account = user.account;
    this.type = user.type;
    this.update(this.page, this.count);
  },
  methods: {
  refreshUrl(uuid, index) {
      this.$http
        .post("/softwareDistribute/rebuildUrl?uuid=" + uuid)
        .then((res) => {
          let {
            data: { code, message },
          } = res;
          if(code === 0){
             this.$message.success("刷新成功")
            this.update(this.page, this.count);

          }else{
            this.$message.error(message);
          }
        }).catch(()=>{
          this.$message.error('服务器异常');
        })
    },
    hideQrcode(val) {
      document.getElementById(val).innerHTML = "";
    },
    showQrcode(url, id) {
      this.$nextTick(() => {
        new QRCode(id, {
          width: 200, // 二维码宽度，单位像素
          height: 200, // 二维码高度，单位像素
          text: url, // 生成二维码的链接
        });
      });
    },
    uoloadSuccess() {
      console.log("=====");
      this.page = 1;
      this.count = 5;
      this.update(this.page, this.count);
    },
    changeEnaled(id) {
      this.$http
        .post("/softwareDistribute/updatePackEnabledStatus?id=" + id)
        .then((res) => {
          let {
            data: { code, message },
          } = res;
          code === 0
            ? this.$message.success("修改成功")
            : this.$message.error(message);
        });
    },
    toEdit(appId) {
      this.$router.push({ path: "/AppEdit", query: { appId } });
    },
    beforeUpload() {
      this.$message.error("选择了,就不可以移除哦!");
      return false;
    },
    updateDownCodeUrlPost() {
      this.dialogVisible4 = false;
      var that = this;
      this.$http
        .post(
          "/softwareDistribute/updateBuyDownCodeUrl?id=" +
            that.id3 +
            "&url=" +
            that.buyDownCodeUrl
        )
        .then(function (response) {
          that.$load(0);
          if (response.data.code == 0) {
            that.update(that.page, that.count);
            that.$message.success(response.data.message);
          } else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
          that.$load(0);
          that.$message.error("修改失败");
        });
    },
    //修改购买地址
    updateDownCodeUrl(id, downCodeUrl) {
      this.dialogVisible4 = true;
      console.log(downCodeUrl);
      this.buyDownCodeUrl = downCodeUrl;
      this.id3 = id;
    },

    //开启下载码
    use(scope) {
      this.dialogVisible2 = false;
      console.log(scope.row.downCode);
      var that = this;
      if (scope.row.downCode === true) {
        scope.row.downCode = 1;
      } else {
        scope.row.downCode = 0;
      }
      this.$http
        .post(
          "/softwareDistribute/updateDownCodeStatus?id=" +
            scope.row.id +
            "&downCode=" +
            scope.row.downCode
        )
        .then(function (response) {
          that.$load(0);
          if (response.data.code == 0) {
            that.update(that.page, that.count);
          } else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
          that.$load(0);
          that.$message.error("操作失败");
        });
    },
    //管理员不能修改其他人的应用
    admin(account, zhi, mess) {
      if (zhi === mess) {
        return true;
      } else {
        return false;
      }
    },
    handleSizeChange(val) {
      console.log("每页数量" + val);
      this.count = val;
      this.update(this.page, this.count);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.update(this.page, this.count);
      console.log(`当前页: ${val}`);
    },
    //修改简介
    updateIntroduce(id, introduce) {
      this.dialogVisible2 = true;
      console.log(introduce);
      this.introduce = introduce;
      this.id2 = id;
    },
    updateIntroducePost() {
      this.dialogVisible2 = false;
      var that = this;
      this.$http
        .post(
          "/softwareDistribute/updateIntroduce?id=" +
            that.id2 +
            "&introduce=" +
            that.introduce
        )
        .then(function (response) {
          that.$load(0);
          if (response.data.code == 0) {
            that.update(that.page, that.count);
            that.$message.success(response.data.message);
          } else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
          that.$load(0);
          that.$message.error("修改失败");
        });
    },
    //上传轮播图
    uploadImages(id) {
      this.fileList = [];
      this.dialogVisible3 = true;
      this.id2 = id;
      this.img = [];
    },
    uploadImagesPost() {
      if (this.img.length != 4) {
        this.$message.error("需要四张图哦!");
      } else {
        this.dialogVisible3 = false;
        let fordata = new FormData();
        fordata.append("img1", this.img[0]);
        fordata.append("img2", this.img[1]);
        fordata.append("img3", this.img[2]);
        fordata.append("img4", this.img[3]);
        fordata.append("id", this.id2);
        var that = this;
        this.$http
          .post("/softwareDistribute/uploadImg", fordata)
          .then(function (response) {
            that.$load(0);
            if (response.data.code == 0) {
              that.update(that.page, that.count);
              that.$message.success(response.data.message);
            } else {
              that.$message.error(response.data.message);
            }
          })
          .catch(function (error) {
            that.$load(0);
            that.$message.error("上传失败");
          });
      }
    },
    uploadImagesChange(e) {
      this.img.push(e.raw);
      console.log(this.img);
    },
    handleClick(row) {
      var that = this;

      this.$http
        .post("/softwareDistribute/delete?uuid=" + row)
        .then(function (response) {
          that.$load(0);
          if (response.data.code == 0) {
            that.update(that.page, that.count);
          } else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
          that.$load(0);
          that.$message, error("删除失败");
        });
    },
    update(pageNum, pageSize) {
      var that = this;
      that.$load(1, "查询中");
      this.$http
        .get(
          "/softwareDistribute/queryAllToC?pageNum=" +
            pageNum +
            "&pageSize=" +
            pageSize
        )
        .then(function (response) {
          that.$load(0);
          if (response.data.code == 0) {
            console.log(response.data.data);
            that.tableData = [];
            for (let res in response.data.data) {
              var temp = response.data.data[res];
              if (temp.apk == null) {
                temp.apk = "未上传";
              } else {
                temp.apk = "已上传";
              }
              temp.enabled = temp.enabled == 1 ? true : false;

              that.tableData.push(temp);
            }
            that.pages = response.data.pages;
            that.total = response.data.total;
          } else {
            that.$message.error(response.data.message);
          }
        })
        .catch(function (error) {
          that.$load(0);
          that.$message, error("查询失败");
        });
    },
    copy(url) {
      var oInput = document.createElement("input"); //创建一个隐藏input（重要！）
      oInput.value = url; //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message.success("复制成功");
    },
    headClass() {
      //表头居中显示
      return "text-align:center";
    },
    rowClass() {
      //表格数据居中显示
      return "text-align:center";
    },
    dialogVisibleOpen(uuid) {
      this.uuid = uuid;
      this.dialogVisible = true;
    },
    success(e) {
      if (e.code === 4) {
        this.$message.error(e.message);
        window.sessionStorage.removeItem("user");
        this.$router.push("/login");
      } else if (e.code === 0) {
        this.$message.success(e.message);
        this.dialogVisible = false;
        this.update(this.page, this.count);
      } else {
        this.$message.error(e.message);
      }
    },
  },
};
</script>
<style lang="less" >
.big_header {
  // border-top: 20px solid #fafafa;
}
.el-pagination {
  .el-input__inner {
    height: 30px;
  }
}
</style>

<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row>
          <el-col :span="2">
            <el-avatar
              :src="require('@/assets/avatar.png')"
              size="80"
            ></el-avatar>
          </el-col>
          <el-col :span="10">
            <span>{{ remain.account }}</span>
            <span class="back_login" @click="quit">（退出登陆）</span>
            <el-button round type="success" @click="addApp">发布应用</el-button>
            <el-button round type="primary" @click="dialogVisible = true"
              >兑换</el-button
            >
            <el-button round type="danger" @click="toCzH5">充值</el-button>
            <div style="margin-top: 20px">
              <i class="el-icon-user-solid"></i>
              {{ remain.typeDesc }}
            </div>
          </el-col>
          <el-col :span="2" :offset="10">
            <div style="color: #999">剩余下载点数</div>
            <div>
              <span class="add_num">{{ remain.downloadPoints }}</span>
            </div>
          </el-col>
          <!-- <el-col :span="3">
            <el-button round type="primary">购买设备</el-button>
          </el-col> -->
        </el-row>
      </div>
      <el-row class="today_count">
        <el-col :span="6">
          <div class="today_title">今日下载数</div>
          <div>{{ remain.todayDownloadCount }}</div>
        </el-col>

        <el-col :span="6">
          <div class="today_title">全部下载数</div>
          <div>{{ remain.downloadCount }}</div>
        </el-col>
      </el-row>
    </el-card>

    <UploadApp
      ref="UploadApp"
      actionUrl="/softwareDistribute/uploadIpa"
      @uoloadSuccess="uoloadSuccess"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      center
      width="500px"
      title="使用兑换码"
      class="dialog_exchange"
    >
      <el-form
        class="demo-form-inline"
        inline
        label-width="100px"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="兑换码" required>
          <el-input v-model="code" placeholder="兑换码"></el-input>
        </el-form-item>

        <el-form-item style="text-align: right; width: 100%">
          <el-button @click="resetForm('ruleForm')" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="submitForm('ruleForm')" size="small"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import UploadApp from "@/components/UploadApp";
export default {
  name: "",
  data() {
    return {
      code: "",
      remain: {},
      dialogVisible: false,
    };
  },
  components: { UploadApp },
  created() {
    this.queryRemainCount();
  },
  mounted() {},
  methods: {
    toCzH5() {
      window.open("https://www.fircc.com/");
    },
    resetForm() {
      this.code = "";
      this.dialogVisible = false;
    },
    submitForm() {
      if (!this.code) {
        this.$message.error("请输入兑换码");
        return;
      }
      this.$http
        .post("/softwareDistribute/useCode?code=" + this.code)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.message);
            this.code = "";
            this.dialogVisible = false;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    exchange() {},
    uoloadSuccess() {
      this.$emit("uoloadSuccess");
    },
    addApp() {
      this.$refs.UploadApp.dialogVisible = true;
    },
    queryRemainCount() {
      this.$http.get("/softwareDistribute/queryRemainCount").then((res) => {
        console.log(res, "res");
        if (res.data.code === 0) {
          this.remain = res.data.data;
        }
      });
    },
    quit() {
      var that = this;
      this.$http.get("/user/quit").then(function (result) {
        that.$message.success(result.data.message);
        window.sessionStorage.removeItem("user");
        that.$router.push("/login");
      });
    },
  },
};
</script>
<style lang='less' >
.dialog_exchange {
  .el-input {
    width: 300px;
  }
}
.box-card {
  margin-bottom: 20px;
  .el-avatar {
    width: 80px;
    height: 80px;
  }
  .back_login {
    color: #ff5722;
    font-size: 14px;
    cursor: pointer;
  }
  .add_num {
    font-size: 30px;
    font-weight: bold;
  }
  .today_count {
    font-size: 24px;
    .today_title {
      font-size: 16px;
      color: #999;
      margin-bottom: 5px;
    }
  }
}
</style>

<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    header-row-class-name="data-table-header"
  >
    <el-table-column prop="date" label="版本" width="180"> </el-table-column>
    <el-table-column prop="name" label="大小(MB)	" width="180">
    </el-table-column>
    <el-table-column prop="address" label="下载次数	"> </el-table-column>
    <el-table-column prop="address" label="装机次数	"> </el-table-column>
    <el-table-column prop="address" label="更新时间"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='less' scoped>
</style>

<template>
  <el-form ref="form" :model="form" label-width="80px">
    <!-- <el-form-item label="应用状态">
      <el-switch
        v-model="form.downCode"
        active-color="#13ce66"
        inactive-color="#ff4949"
      >
      </el-switch>
    </el-form-item> -->
    <!-- <el-form-item label="安装方式">
      <el-radio-group v-model="form.radio">
        <el-radio :label="3">公开安装</el-radio>
        <el-radio :label="6">密码安装</el-radio>
        <el-radio :label="9">授权安装</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="安装密码">
      <el-input placeholder="请输入安装密码" type="small"></el-input>
    </el-form-item>
    <el-form-item label="发卡地址">
      <el-input placeholder="请输入发卡平台地址" type="small"></el-input>
    </el-form-item>
    <el-form-item label="下载完后">
      <el-radio-group v-model="form.radio">
        <el-radio :label="3">直接打开</el-radio>
        <el-radio :label="6">桌面打开</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="消息推送">
      <el-radio disabled v-model="form.radio" label="直接推送"
        >直接推送</el-radio
      >
    </el-form-item>
    <el-form-item label="设置语言">
      <el-radio-group v-model="form.radio">
        <el-radio :label="3">中文</el-radio>
        <el-radio :label="6">英文</el-radio>
        <el-radio :label="6">越南语</el-radio>
      </el-radio-group>
    </el-form-item> -->
    <el-form-item label="应用名字">
      <el-input v-model="appName"></el-input>
    </el-form-item>
    <el-form-item label="应用介绍">
      <el-input type="textarea" v-model="introduce"></el-input>
    </el-form-item>
    <!-- <el-form-item label="更新说明">
      <el-input type="textarea"></el-input>
    </el-form-item> -->
    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "",
  props: ["id"],
  data() {
    return {
      form: {
        downCode: true,
      },
      appName: "",
      introduce: "",
    };
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      this.$http
        .post(
          "/softwareDistribute/updatePackInfo?appName=" +
            this.appName +
            "&introduce=" +
            this.introduce +
            "&id=" +
            this.id
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>
<style lang='less' scoped>
</style>

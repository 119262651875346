<template>
  <el-form ref="form" :model="form" label-width="150px">
    <el-form-item label="限量下载:">
      <div>
        <el-input-number
          v-model="form.num"
          @change="handleChange"
          :min="1"
          :max="10"
          label="描述文字"
          size="small"
        ></el-input-number>
        <el-button type="primary" size="small" style="margin-left: 20px"
          >保存</el-button
        >
      </div>
      <div class="tips">
        此功能仅限制新设备，已下载过的设备，不受限制。限制下载量达到您的预设值时，该应用将会限制下载。您可修改下载上限。（0为不限制）
      </div>
    </el-form-item>
    <el-form-item label="每天限量下载:">
      <div>
        <el-input-number
          v-model="form.num"
          @change="handleChange"
          :min="1"
          :max="10"
          label="描述文字"
          size="small"
        ></el-input-number>
        <el-button type="primary" size="small" style="margin-left: 20px"
          >保存</el-button
        >
      </div>
      <div class="tips">
        此功能仅限制新设备，已下载过的设备，不受限制。每天限制下载量达到您的预设值时，该应用将会限制下载。您可修改每天下载上限，或等待第二日自动重新上架。（0为不限制）
      </div>
    </el-form-item>
    <el-form-item label="同IP限量下载(分钟):">
      <div>
        <el-input-number
          v-model="form.num"
          @change="handleChange"
          :min="1"
          :max="10"
          label="描述文字"
          size="small"
        ></el-input-number>
        <el-button type="primary" size="small" style="margin-left: 20px"
          >保存</el-button
        >
      </div>
      <div class="tips">
        同IP限量下载限制表示同一IP几分钟下载1次，量达到您的预设值时，该应用将会限制下载。您可修改时间上限。（0为不限制）
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      form: { num: "" },
    };
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>
<style lang='less' scoped>
.tips {
  color: #999;
  padding: 9px 0;
}
</style>

import Vue from 'vue';
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");

import { TooltipComponent } from 'echarts/components';

import { GridComponent } from 'echarts/components';
echarts.use([GridComponent]);
echarts.use([TooltipComponent]);

Vue.prototype.$echarts = echarts
<template>
  <div class="">
    <el-radio-group v-model="radio" style="width: 100%">
      <div>
        <el-radio :label="3">Andriod下载地址</el-radio>
        <el-row style="margin: 20px 0">
          <el-col :span="10">
            <el-input size="small"></el-input>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-button type="primary" size="small">保存</el-button>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-radio :label="6" style="margin-bottom: 20px"
          >Andriod应用上传</el-radio
        >
        <!-- <upload /> -->

        <el-upload
          :data="id"
          :on-success="success"
          class="uploadHead"
          with-credentials
          drag
          :action="
            this.$http.defaults.baseURL + '/softwareDistribute/uploadApk'
          "
          name="apk"
          accept=".apk"
          :multiple="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传apk文件，且不超过2048MB
          </div>
        </el-upload>
      </div>
    </el-radio-group>
  </div>
</template>

<script>
import upload from "@/components/upload";
export default {
  name: "",
  data() {
    return { radio: 3 };
  },
  components: { upload },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='less' scoped>
</style>

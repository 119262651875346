<template>
  <el-upload
    :on-success="success"
    class="uploadHead"
    with-credentials
    drag
    :action="this.$http.defaults.baseURL + '/softwareDistribute/uploadApk'"
    name="apk"
    accept=".apk"
    :multiple="false"
  >
    <i class="el-icon-upload"></i>

    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
    <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过2048MB</div>
  </el-upload>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    success() {},
  },
};
</script>
<style lang='less' scoped>
</style>
